import React, { useContext } from 'react'
import styled, { css, ThemeContext } from 'styled-components'

import { DefaultTheme } from '../themes'

const Svg = styled.svg(
  ({ theme }) => css`
    fill: ${theme.color.grey['500']};
  `,
)

type Props = {
  'data-testid'?: string
  open?: boolean
  className?: string
}

export const Hamburger: React.FC<Props> = ({ className = '' }) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  return (
    <Svg
      className={className}
      theme={theme}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 7.99996C4 7.26358 4.59695 6.66663 5.33333 6.66663H26.6667C27.403 6.66663 28 7.26358 28 7.99996C28 8.73634 27.403 9.33329 26.6667 9.33329H5.33333C4.59695 9.33329 4 8.73634 4 7.99996ZM4 16C4 15.2636 4.59695 14.6666 5.33333 14.6666H26.6667C27.403 14.6666 28 15.2636 28 16C28 16.7363 27.403 17.3333 26.6667 17.3333H5.33333C4.59695 17.3333 4 16.7363 4 16ZM4 24C4 23.2636 4.59695 22.6666 5.33333 22.6666H26.6667C27.403 22.6666 28 23.2636 28 24C28 24.7363 27.403 25.3333 26.6667 25.3333H5.33333C4.59695 25.3333 4 24.7363 4 24Z"
        fill="#71717A"
      />
    </Svg>
  )
}
Hamburger.displayName = 'Hamburger'
