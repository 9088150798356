import { useCallback, useReducer, useState } from 'react'

import { useQuery } from '../../lib/graphql/useQuery'
import { useUser } from '../../Context/AuthContext/hooks/useUser'
import { compareRecords } from '../../lib/utils'
import { ConversationData, NotificationData } from './types'
import { CONVERSATIONS_QUERY } from './query'

export const useChatNotification = (fn: (data: NotificationData) => void) => {
  const { data: user } = useUser()
  const uid = user?.id
  const reducer = useCallback(
    (state: NotificationData | null, newState: NotificationData | null) => {
      if (!state || !newState || newState.uid === uid) {
        return newState
      }
      if (!compareRecords(state, newState) && state.when < newState.when) {
        fn(newState)
        return newState
      }
      return state
    },
    [fn, uid],
  )
  const [amount, setAmount] = useState(0)
  const [, dispatch] = useReducer(reducer, null)
  useQuery<ConversationData>(
    CONVERSATIONS_QUERY,
    {
      run: Boolean(uid),
    },
    {
      refetchInterval: 5000,
      onSuccess: (data: ConversationData) => {
        const { getUnreadMessageCount: amount } = data
        setAmount(amount)
        if ((data?.field?.results || []).length > 0) {
          const {
            field: {
              results: [
                {
                  getUnreadMessageCount,
                  messages: {
                    results: [
                      {
                        content,
                        when,
                        sender: { id, fullName, avatar },
                      },
                    ],
                  },
                },
              ],
            },
          } = data
          dispatch({
            uid: id,
            userName: fullName,
            icon: avatar,
            when: new Date(when).getTime(),
            messageNumber: getUnreadMessageCount,
            text: content,
          })
        }
      },
    },
  )

  return amount
}
