import styled, { css } from 'styled-components'

import { pxToRem } from '../../../lib/utils'
import { NextLink } from '../../../lib/router'

export const Anchor = styled.a(
  ({ theme }) => css`
    color: ${theme.color.grey['700']};
    border: 0.125rem solid transparent;
    border-radius: ${theme.radius.small};
    text-decoration: none;
    font-weight: ${theme.font.weight.medium};
    display: block;
    font-size: ${pxToRem(16)};
    padding: 0.6875rem 0 0.6875rem 1rem;
    line-height: ${pxToRem(14)};
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    text-align: left;

    :focus {
      outline: none;
    }

    :focus-visible {
      border-color: ${theme.color.grey['700']};
    }

    :hover {
      text-decoration: none;
    }

    &.mobile {
      display: none;
      @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
        display: block;
      }
    }
  `,
)

export const NAnchor = styled(NextLink)(
  ({ theme }) => css`
    color: ${theme.color.grey['700']};
    border: 0.125rem solid transparent;
    border-radius: ${theme.radius.small};
    text-decoration: none;
    font-weight: ${theme.font.weight.medium};
    display: block;
    font-size: ${pxToRem(16)};
    padding: 0.6875rem 0 0.6875rem 1rem;
    line-height: ${pxToRem(14)};
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    text-align: left;

    :focus {
      outline: none;
    }

    :focus-visible {
      border-color: ${theme.color.grey['700']};
    }

    :hover {
      text-decoration: none;
    }

    &.mobile {
      display: none;
      @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
        display: block;
      }
    }
  `,
)
