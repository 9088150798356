import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useUser } from '@style-space/components/src/Context/AuthContext/hooks/useUser'
import { TopPanel as MasterTopPanel } from '@style-space/components/src/TopPanel'
import { AppMenu } from '@style-space/components/src/Menu/AppMenu'
import { ChatNotification } from '@style-space/components/src/ChatNotification'
import { HamburgerMenu } from '@style-space/components/src/Menu/HamburgerMenu'
import { Button } from '@style-space/components/src/Button'

import { TopPanelSearch } from './public/SearchBar/TopPanelSearch/TopPanelSearch'
import { SearchValues } from './public/SearchBar/SearchBar'
import { SignModal } from './account/SignModal'

const SMasterTopPanel = styled(MasterTopPanel)(
  ({ theme }) => css`
    &.transparent {
      .top-panel-background {
        background-color: transparent;
      }
      &.shadow {
        .top-panel-background {
          background-color: ${theme.color.white};
        }
      }
    }
  `,
)

const InnerWrap = styled.div(
  ({ theme }) => css`
    height: 100%;
    display: flex;
    z-index: 1;
    position: relative;
    align-items: center;
    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      display: none;
    }
  `,
)
const SHamburgerMenu = styled(HamburgerMenu)(
  ({ theme }) => css`
    display: none;
    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      display: block;
    }
  `,
)

const SButtonLink = styled(Button)(
  ({ theme }) => css`
    && {
      color: ${theme.color.grey['800']};
      font-weight: ${theme.font.weight.semibold};
    }
    :last-of-type {
      margin-left: 0.9375rem;
    }
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      :last-of-type {
        margin-left: 0;
      }
    }
  `,
)

const LOGO_MOBILE = '/logo_gray.svg'
const LOGO = '/logo_gray_long.svg'

type Props = {
  transparent?: boolean
  values?: SearchValues
  className?: string
}

export const TopPanel: React.FC<Props> = ({
  values,
  transparent,
  className = '',
}) => {
  const [modal, setModal] = useState<SignModal>(null)
  const transparentClass = transparent ? 'transparent' : ''
  const { data: user } = useUser()

  const onSignUp = React.useCallback(() => {
    setModal('signUp')
  }, [setModal])

  const onSignIn = React.useCallback(() => {
    setModal('signIn')
  }, [setModal])

  if (user) {
    return (
      <>
        <SMasterTopPanel
          className={`${className} ${transparentClass}`}
          logo={LOGO}
          mobileLogo={LOGO_MOBILE}
        >
          <TopPanelSearch values={values} />
          <ChatNotification>
            {({ amount }) => <AppMenu messagesAmount={amount} />}
          </ChatNotification>
        </SMasterTopPanel>
      </>
    )
  }

  return (
    <>
      <SMasterTopPanel
        className={`${className} ${transparentClass}`}
        logo={LOGO}
        mobileLogo={LOGO_MOBILE}
      >
        <TopPanelSearch values={values} />
        <SHamburgerMenu signup={onSignUp} login={onSignIn} />
        <InnerWrap>
          <SButtonLink
            buttonType="link"
            uppercase={false}
            data-testid="topPanelSignUp"
            onClick={onSignUp}
          >
            Sign up
          </SButtonLink>
          <SButtonLink
            buttonType="link"
            uppercase={false}
            data-testid="topPanelSignIn"
            onClick={onSignIn}
          >
            Sign in
          </SButtonLink>
        </InnerWrap>
      </SMasterTopPanel>
      <SignModal modal={modal} setModal={setModal} />
    </>
  )
}
