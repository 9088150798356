import React, { useContext } from 'react'
import { Menu } from '@headlessui/react'
import { ThemeContext } from 'styled-components'

import { NextMenuItem } from './NextMenuItem'
import { Amount, MenuItemInner } from './MenuItem.style'
import { DefaultTheme } from '../../themes'
import { slugify } from '../../lib/utils'

type Props = {
  amount?: number
  href?: string
  mobile?: boolean
  onClick?(): void
  'data-testid'?: string
  children: string
}

export const MenuItem: React.FC<Props> = ({
  href,
  amount,
  mobile,
  onClick,
  'data-testid': dataTestID = 'headless-menu-item',
  children,
}) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  return (
    <Menu.Item
      as={NextMenuItem}
      data-testid={dataTestID}
      href={href}
      mobile={mobile}
      className={slugify(children)}
      onClick={onClick}
    >
      {({ active }) => (
        <MenuItemInner theme={theme} className={active ? 'active' : ''}>
          {children}
          {Boolean(amount) && <Amount theme={theme}> ({amount})</Amount>}
        </MenuItemInner>
      )}
    </Menu.Item>
  )
}
