import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { EXTERNAL_ROUTES, getSearchRoute } from '../lib/utils/routes'
import { DefaultProps } from '../types'
import {
  Wrap,
  Background,
  InnerWrap,
  CenterWrap,
  Logo,
  SAnchor,
  SessionsLink,
  BlogLink,
  LinkMenuWrap,
} from './TopPanel.style'
import { DefaultTheme } from '../themes'
import { ROUTES } from '../lib/utils'

const SHADOW_THRESHOLD = 40

type Props = DefaultProps & {
  logo: string
  mobileLogo: string
  children?: React.ReactNode
}

export const TopPanel: React.FC<Props> = ({
  children,
  className = '',
  logo,
  mobileLogo,
}) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  const [shadow, setShadow] = React.useState(false)
  const [scrollOpacity, setScrollOpacity] = React.useState(1)

  React.useEffect(() => {
    const listener = () => {
      if (window.scrollY >= SHADOW_THRESHOLD) {
        setShadow(true)
      } else {
        setScrollOpacity(1 - window.scrollY / SHADOW_THRESHOLD)
        setShadow(false)
      }
    }
    listener()
    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
  }, [])

  const shadowClass = shadow ? 'shadow ' : ''

  return (
    <Wrap
      theme={theme}
      data-testid="top-panel"
      className={`${shadowClass} ${className}`}
    >
      <CenterWrap theme={theme}>
        <Background theme={theme} className="top-panel-background" />
        <InnerWrap theme={theme}>
          <SAnchor
            nextLink
            href={ROUTES.home}
            theme={theme}
            data-testid="top-panel-logo"
          >
            <Logo theme={theme} src={logo} alt="Style Space Logo" />
            <Logo
              theme={theme}
              className={'mobile'}
              src={mobileLogo}
              alt="Style Space Logo"
            />
          </SAnchor>
          {!shadow && (
            <LinkMenuWrap style={{ opacity: scrollOpacity }}>
              <SessionsLink
                nextLink
                href={getSearchRoute()}
                theme={theme}
                bold
                underline={false}
              >
                Sessions
              </SessionsLink>
              <BlogLink
                nextLink
                href={ROUTES.blog}
                theme={theme}
                bold
                underline={false}
              >
                Blog
              </BlogLink>
              <BlogLink
                nextLink
                href={EXTERNAL_ROUTES.business}
                theme={theme}
                bold
                underline={false}
              >
                Workforce Styling
              </BlogLink>
              <BlogLink
                nextLink
                href={EXTERNAL_ROUTES.veteran}
                theme={theme}
                bold
                underline={false}
              >
                Veteran Styling
              </BlogLink>
            </LinkMenuWrap>
          )}
        </InnerWrap>
        {children}
      </CenterWrap>
    </Wrap>
  )
}
TopPanel.displayName = 'TopPanel'
