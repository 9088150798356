import React from 'react'

import { DefaultProps } from '../../types'
import { Menu } from '../Menu'
import { MenuItem } from '../MenuItem'
import {
  ADMIN_ROUTES,
  getChatRoute,
  getLogoutRoute,
  ROUTES,
} from '../../lib/utils'

type Props = DefaultProps & {
  initials: string
  messagesAmount?: number
  avatar?: string
}

export const AdminMenu: React.FC<Props> = ({
  messagesAmount,
  className,
  initials,
  avatar,
}) => {
  return (
    <Menu className={className} initials={initials} avatar={avatar}>
      <MenuItem
        href={ADMIN_ROUTES.applications}
        data-testid="admin-menu-applications"
      >
        Applications
      </MenuItem>
      <MenuItem href={ADMIN_ROUTES.bookings} data-testid="admin-menu-bookings">
        Bookings
      </MenuItem>
      <MenuItem
        amount={messagesAmount}
        href={getChatRoute('')}
        data-testid="stylist-menu-chat"
      >
        Messages
      </MenuItem>
      <MenuItem
        href={getLogoutRoute(ROUTES.home)}
        data-testid="admin-menu-logout"
      >
        Log out
      </MenuItem>
    </Menu>
  )
}
AdminMenu.displayName = 'AdminMenu'
