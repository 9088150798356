import styled, { css } from 'styled-components'

import { pxToRem } from '../lib/utils'

export const Wrap = styled.div(
  ({ theme }) => css`
    background-color: ${theme.color.primary['500']};
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.stylist {
      background-color: ${theme.color.secondary['400']};
    }
  `,
)

export const Text = styled.div(
  ({ theme }) => css`
    position: relative;
    top: ${pxToRem(1)};
    color: ${theme.color.white};
    font-size: ${pxToRem(18)};
    line-height: 1;
  `,
)
