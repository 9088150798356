import styled, { css } from 'styled-components'

import { Anchor } from '../Anchor'
import { pxToRem } from '../lib/utils'

export const Wrap = styled.div(
  ({ theme }) => css`
    box-sizing: border-box;
    width: 100%;
    height: ${pxToRem(88)};
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    box-shadow: none;

    &.shadow {
      box-shadow: ${theme.boxShadow.default};
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      height: ${pxToRem(75)};
    }
  `,
)

export const Background = styled.div(
  ({ theme }) => css`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    box-shadow: none;
    background-color: ${theme.color.white};
  `,
)

export const CenterWrap = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: ${pxToRem(0, 20)};
  max-width: ${pxToRem(1288)};
`

export const InnerWrap = styled.div`
  height: 100%;
  display: flex;
  position: relative;
  z-index: 2;
  align-items: center;
  justify-content: space-between;
`

export const Logo = styled.img(
  ({ theme }) => css`
    display: block;
    width: 100%;
    height: auto;
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      display: none;
    }

    &.mobile {
      display: none;

      @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
        display: block;
      }
    }
  `,
)

export const SAnchor = styled(Anchor)(
  ({ theme }) => css`
    position: relative;
    left: ${pxToRem(-4)};
    width: 10.1875rem;
    height: auto;

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      width: 2rem;
    }
  `,
)

export const SessionsLink = styled(Anchor)(
  ({ theme }) => css`
    font-size: ${pxToRem(16)};
    margin-left: ${pxToRem(64)};

    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      margin-left: ${pxToRem(24)};
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      display: none;
    }
  `,
)

export const BlogLink = styled(Anchor)(
  ({ theme }) => css`
    font-size: ${pxToRem(16)};
    margin-left: ${pxToRem(28)};

    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      margin-left: ${pxToRem(16)};
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      display: none;
    }
  `,
)

export const LinkMenuWrap = styled.div`
  display: flex;
  align-items: center;
`
