import React from 'react'
import { toast } from 'react-toastify'

import { getChatRoute } from '../lib/utils'
import { MessageNotification } from '../MessageNotification'
import { MESSAGE_TOAST_CONTAINER_ID } from './constants'
import { MessageToastContainer } from './MessageToastContainer'
import { useChatNotification } from './useChatNotification'
import { NotificationData } from './useChatNotification/types'

export const messageToast = (params: NotificationData) => {
  toast.dismiss()
  const href = getChatRoute(params.uid)
  toast(<MessageNotification {...params} href={href} />, {
    containerId: MESSAGE_TOAST_CONTAINER_ID,
  })
}

type Props = {
  children?: JSX.Element | (({ amount }: { amount: number }) => JSX.Element)
}

export const ChatNotification: React.FC<Props> = ({ children }) => {
  const amount = useChatNotification((data: NotificationData) => {
    messageToast(data)
  })

  if (typeof children === 'function') {
    return (
      <>
        {children({ amount })}
        <MessageToastContainer />
      </>
    )
  }

  return (
    <>
      {children}
      <MessageToastContainer />
    </>
  )
}
ChatNotification.displayName = 'ChatNotifications'
