import React, { HTMLAttributes, useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { DefaultTheme } from '../../../themes'
import { Anchor, NAnchor } from './NextMenuItem.style'

export const ZONES = ['chat', 'admin']

type Props = HTMLAttributes<HTMLAnchorElement> & {
  href?: string
  mobile?: boolean
  'data-testid'?: string
}

export const NextMenuItem: React.FC<Props> = ({
  'data-testid': dataTestID = 'next-menu-item',
  href,
  mobile,
  children,
  className,
  ...rest
}) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  if (!href) {
    return (
      <Anchor
        className={`${mobile ? 'mobile ' : ''}${className}`}
        theme={theme}
        data-testid={dataTestID}
        {...(rest as any)}
        as="button"
      >
        {children}
      </Anchor>
    )
  }

  return (
    <NAnchor
      className={`${mobile ? 'mobile ' : ''}${className}`}
      theme={theme}
      href={href}
      data-testid={dataTestID}
      {...rest}
    >
      {children}
    </NAnchor>
  )
}
NextMenuItem.displayName = 'NextMenuItem'
