import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { Wrap, Text } from './InitialsAvatar.style'
import { DefaultTheme } from '../themes'

type Props = {
  className?: string
  children?: React.ReactNode
}

export const InitialsAvatar: React.FC<Props> = ({
  className = '',
  children,
}) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  return (
    <Wrap
      theme={theme}
      data-testid="menu-initials"
      className={`menu-initials ${className}`}
    >
      <Text theme={theme}>{children}</Text>
    </Wrap>
  )
}
InitialsAvatar.displayName = 'InitialsAvatar'
