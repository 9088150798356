import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Menu } from '@headlessui/react'

import {
  ButtonWrap,
  SS3NextImage,
  SChevron,
  SXIcon,
  Amount,
} from './MenuButton.style'
import { DefaultProps } from '../../types'
import { DefaultTheme } from '../../themes'
import { InitialsAvatar } from '../../InitialsAvatar'
import { pxToRem } from '../../lib/utils'

type Props = DefaultProps & {
  isOpen?: boolean
  amount?: number
  avatar?: string
  children: string
}

export const MenuButton: React.FC<Props> = ({
  className = '',
  isOpen,
  amount,
  avatar,
  children,
}) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  const openClass = isOpen ? 'open' : ''
  if (isOpen) {
    return (
      <ButtonWrap theme={theme}>
        <Menu.Button
          data-testid="menu-button"
          className={`${openClass} ${className} menubtn`}
        >
          <SChevron
            data-testid="menu-button-chevron"
            theme={theme}
            open={true}
          />
          <SXIcon data-testid="menu-button-xicon" theme={theme} />
        </Menu.Button>
      </ButtonWrap>
    )
  }
  return (
    <ButtonWrap theme={theme}>
      <Menu.Button
        data-testid="menu-button"
        className={`${openClass} ${className} menubtn`}
      >
        <InitialsAvatar>{children}</InitialsAvatar>
        {avatar && (
          <SS3NextImage
            sizes={pxToRem(40)}
            data-testid="menu-button-image"
            alt=""
            src={avatar}
          />
        )}
        {amount && <Amount theme={theme}>{amount > 9 ? '9+' : amount}</Amount>}
      </Menu.Button>
    </ButtonWrap>
  )
}
MenuButton.displayName = 'MenuButton'
