import styled, { css } from 'styled-components'

import { pxToRem } from '../../lib/utils'

export const MenuItemInner = styled.span(
  ({ theme }) => css`
    font-size: ${pxToRem(16)};
    line-height: 1.5;
    color: ${theme.color.grey['900']};
    font-weight: ${theme.font.weight.regular};

    :hover {
      color: ${theme.color.primary['500']};
      text-decoration: none;
      background-color: transparent;
    }

    &.active {
      color: ${theme.color.primary['500']};
      text-decoration: none;
      background-color: transparent;
    }
  `,
)

export const Amount = styled.span(
  ({ theme }) => css`
    color: ${theme.color.secondary['500']};
  `,
)
