import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'

import { SearchValues } from '../SearchBar'
import { CLASSNAME, ID } from '../HeroSearch/HeroSearch'
import {
  DesktopMockSearch,
  MenuItems,
  SMagnifier,
  SSearchBar,
} from './TopPanelSearch.style'

type Props = {
  values?: SearchValues
}

export const TopPanelSearch: React.FC<Props> = ({ values }) => {
  const [sticky, setSticky] = React.useState(false)
  React.useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    const listener = () => {
      const elem = document.getElementById(ID)
      if (!elem) {
        return
      }

      const classList = elem.className.split(' ')
      setSticky(classList.includes(CLASSNAME))
    }
    listener()
    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
  }, [sticky])

  if (!sticky) {
    return null
  }
  return (
    <Menu>
      {({ open }) => (
        <>
          <Transition
            as={Fragment}
            show={!open}
            enter="enter"
            enterFrom="enter-from"
            enterTo="enter-to"
            leave="enter"
            leaveFrom="enter-to"
            leaveTo="enter-from"
          >
            <DesktopMockSearch>
              <SMagnifier />
              Search Style Session
            </DesktopMockSearch>
          </Transition>
          <Transition
            as={Fragment}
            show={open}
            enter="enter"
            enterFrom="enter-from"
            enterTo="enter-to"
            leave="enter"
            leaveFrom="enter-to"
            leaveTo="enter-from"
          >
            <MenuItems>
              <SSearchBar small values={values} />
            </MenuItems>
          </Transition>
        </>
      )}
    </Menu>
  )
}
TopPanelSearch.displayName = 'TopPanelSearch'
