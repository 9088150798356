import React from 'react'

import { AdminMenu } from '../AdminMenu'
import { StylistMenu } from '../StylistMenu'
import { CustomerMenu } from '../CustomerMenu'
import { useAppMode } from '../../Context/AuthContext/hooks/useAppMode'
import { useUser } from '../../Context/AuthContext/hooks/useUser'
import { DefaultProps } from '../../types'
import { getNameInitials } from '../../lib/utils'

type Props = DefaultProps & {
  messagesAmount?: number
}

export const AppMenu: React.FC<Props> = ({ className, messagesAmount }) => {
  const { data } = useUser()
  const { mode } = useAppMode()

  if (!data) {
    return null
  }

  if (mode === 'admin' || data.isAdmin) {
    return (
      <AdminMenu
        messagesAmount={messagesAmount}
        className={className}
        initials="AS"
      />
    )
  }
  if (mode === 'stylist') {
    return (
      <StylistMenu
        messagesAmount={messagesAmount}
        className={className}
        initials={getNameInitials(data.fullName) || ''}
        avatar={data?.avatar}
      />
    )
  }

  return (
    <CustomerMenu
      messagesAmount={messagesAmount}
      initials={getNameInitials(data.fullName) || ''}
      className={className}
      avatar={data?.avatar}
      isStylist={data?.isStylist}
      isStylistApplicant={data?.isStylistApplicant}
    />
  )
}
AppMenu.displayName = 'StylistMenu'
