import React from 'react'

import { DefaultProps } from '../../types'
import { EXTERNAL_ROUTES, ROUTES, getSearchRoute } from '../../lib/utils/routes'
import { Menu } from '../Menu'
import { MenuItem } from '../MenuItem'

type Props = DefaultProps & {
  external?: boolean
  signup?: () => void
  login?: () => void
}

export const HamburgerMenu: React.FC<Props> = ({
  external,
  className,
  signup,
  login,
}) => {
  return (
    <Menu className={className} initials="ab" hamburgerButton={true}>
      <MenuItem
        href={
          external
            ? `${EXTERNAL_ROUTES.base}${getSearchRoute()}`
            : getSearchRoute()
        }
        data-testid="hamburger-menu-sessions"
      >
        Sessions
      </MenuItem>
      <MenuItem
        href={external ? `${EXTERNAL_ROUTES.base}${ROUTES.blog}` : ROUTES.blog}
        data-testid="hamburger-menu-blog"
      >
        Blog
      </MenuItem>
      <MenuItem
        href={EXTERNAL_ROUTES.business}
        data-testid="hamburger-menu-business"
      >
        Workforce Styling
      </MenuItem>
      <MenuItem
        href={EXTERNAL_ROUTES.veteran}
        data-testid="hamburger-menu-veteran"
      >
        Veteran Styling
      </MenuItem>
      {signup && (
        <MenuItem onClick={signup} data-testid="hamburger-menu-signup">
          Sign up
        </MenuItem>
      )}
      {login && (
        <MenuItem onClick={login} data-testid="hamburger-menu-login">
          Log in
        </MenuItem>
      )}
    </Menu>
  )
}
HamburgerMenu.displayName = 'HamburgerMenu'
