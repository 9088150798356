export const CONVERSATIONS_QUERY = `
query ListConversation {
	getUnreadMessageCount
	field: listConversation(pagination: { offset: 0, count: 1 }) {
		results {
			id
		    getUnreadMessageCount	
			messages(pagination: { offset: 0, count: 1 }) {
				results {
					content
					when
					sender {
						id,
						fullName,
						avatar
					}
				}
			}
		}
	}
}
`
