import React, { useContext } from 'react'
import styled, { css, ThemeContext } from 'styled-components'

import { DefaultTheme } from '../themes'

const Svg = styled.svg(
  ({ theme }) => css`
    path {
      fill: ${theme.color.primary['600']};
    }
  `,
)

type Props = {
  className?: string
}

export const Arrow: React.FC<Props> = ({ className }) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  return (
    <Svg
      theme={theme}
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path d="M11.5774 8.88889H3.41667C3.18655 8.88889 3 8.71478 3 8.5C3 8.28522 3.18655 8.11111 3.41667 8.11111H11.5774L8.95537 5.66387C8.79265 5.512 8.79265 5.26577 8.95537 5.1139C9.11809 4.96203 9.38191 4.96203 9.54463 5.1139L12.878 8.22501C13.0407 8.37688 13.0407 8.62312 12.878 8.77499L9.54463 11.8861C9.38191 12.038 9.11809 12.038 8.95537 11.8861C8.79265 11.7342 8.79265 11.488 8.95537 11.3361L11.5774 8.88889Z" />
      <rect x="0.5" y="0.5" width="15" height="15" stroke="white" />
    </Svg>
  )
}
Arrow.displayName = 'Arrow'
