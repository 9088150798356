import styled, { css } from 'styled-components'

import { S3NextImage } from '../../S3NextImage'
import { Chevron } from '../../icons/Chevron'
import { XIcon } from '../../icons/XIcon'
import { pxToRem } from '../../lib/utils'

export const ButtonWrap = styled.div(
  ({ theme }) => css`
    .menubtn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${pxToRem(40)};
      height: ${pxToRem(40)};
      position: relative;
      cursor: pointer;
      overflow: visible;
      border-radius: 50%;
      border-width: 0;

      &.open {
        border: ${pxToRem(3)} solid ${theme.color.grey['500']};
        @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
          border: ${pxToRem(3)} solid transparent;
          background: transparent;
        }
      }
    }
  `,
) as any

export const HamburgerButtonWrap = styled.div(
  ({ theme }) => css`
    .menubtn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${pxToRem(40)};
      height: ${pxToRem(40)};
      position: relative;
      cursor: pointer;
      overflow: hidden;
      border-width: 0;
      background: transparent;
      &.open {
        border: ${pxToRem(3)} solid ${theme.color.grey['500']};
        border-radius: 50%;
        @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
          border: ${pxToRem(3)} solid transparent;
        }
      }
    }
  `,
) as any

export const SS3NextImage = styled(S3NextImage)`
  position: absolute;
  top: ${pxToRem(0)};
  left: ${pxToRem(0)};
  bottom: ${pxToRem(0)};
  right: ${pxToRem(0)};
  width: auto;
  border-radius: 50%;
  overflow: hidden;

  img {
    max-height: ${pxToRem(40)};
  }
`

export const SChevron = styled(Chevron)(
  ({ theme }) => css`
    stroke: ${theme.color.grey['500']};
    fill: none;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke-linejoin: round;
    stroke-linecap: round;
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      display: none;
    }
  `,
)

export const SXIcon = styled(XIcon)(
  ({ theme }) => css`
    display: none;
    stroke: ${theme.color.grey['500']};
    fill: none;
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      display: block;
    }
  `,
)

export const Amount = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: ${pxToRem(-6)};
    top: ${pxToRem(-6)};
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
    border-radius: 50%;
    background: ${theme.color.tertiary['500']};
    border: ${pxToRem(2)} solid ${theme.color.grey['100']};
    text-align: center;
    color: ${theme.color.white};
    font-size: ${pxToRem(12)};
    line-height: 1;
    font-weight: ${theme.font.weight.semibold};
  `,
)
