import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Menu } from '@headlessui/react'

import { Hamburger } from '../../icons/Hamburger'
import { HamburgerButtonWrap, SChevron, SXIcon } from './MenuButton.style'
import { DefaultProps } from '../../types'
import { DefaultTheme } from '../../themes'

type Props = DefaultProps & {
  isOpen?: boolean
}

export const HamburgerButton: React.FC<Props> = ({
  className = '',
  isOpen,
}) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  const openClass = isOpen ? 'open' : ''
  if (isOpen) {
    return (
      <HamburgerButtonWrap theme={theme}>
        <Menu.Button
          data-testid="menu-button"
          className={`${openClass} ${className} menubtn`}
        >
          <SChevron
            data-testid="menu-button-chevron"
            theme={theme}
            open={true}
          />
          <SXIcon data-testid="menu-button-xicon" theme={theme} />
        </Menu.Button>
      </HamburgerButtonWrap>
    )
  }
  return (
    <HamburgerButtonWrap theme={theme}>
      <Menu.Button
        data-testid="menu-button"
        className={`${openClass} ${className} menubtn`}
      >
        <Hamburger />
      </Menu.Button>
    </HamburgerButtonWrap>
  )
}
HamburgerButton.displayName = 'HamburgerButton'
