import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import {
  Wrap,
  InnerWrap,
  MessageWrap,
  SS3NextImage,
  Text,
  UserName,
  ReplyAnchor,
  Notification,
  ReplyText,
  ReplyIcon,
  IconWrap,
} from './MessageNotification.style'
import { DefaultTheme } from '../themes'
import { getNameInitials, pxToRem } from '../lib/utils'
import { InitialsAvatar } from '../InitialsAvatar'

type Props = {
  className?: string
  icon?: string
  userName: string
  text: string
  href: string
  messageNumber: number
}

export const MessageNotification: React.FC<Props> = ({
  className = '',
  icon = '',
  userName = '',
  text = '',
  href = '#',
  messageNumber = 1,
}) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  const notificationNumber = messageNumber > 9 ? '9+ ' : messageNumber + ' '
  const notificationText = messageNumber > 1 ? 'new messages' : 'new message'
  return (
    <Wrap
      theme={theme}
      className={className}
      data-testid="message-notification"
    >
      <InnerWrap theme={theme}>
        <IconWrap theme={theme}>
          <InitialsAvatar>{getNameInitials(userName)}</InitialsAvatar>
          {icon && (
            <SS3NextImage
              theme={theme}
              src={icon}
              alt=""
              sizes={`${pxToRem(40)}`}
              className="notification-icon"
              data-testid="notification-icon"
            />
          )}
        </IconWrap>
        <MessageWrap theme={theme}>
          <UserName theme={theme} data-testid="notification-username">
            {userName}
          </UserName>
          <Text theme={theme} data-testid="notification-text">
            {text}
          </Text>
        </MessageWrap>
        <Notification data-testid="notification-mobile-text" theme={theme}>
          {notificationNumber}
          {notificationText}
        </Notification>
      </InnerWrap>
      <ReplyAnchor href={href} theme={theme} data-testid="message-anchor">
        <ReplyText theme={theme}>Reply</ReplyText>
        <ReplyIcon theme={theme} />
      </ReplyAnchor>
    </Wrap>
  )
}
MessageNotification.displayName = 'MessageNotification'
