import styled, { css } from 'styled-components'
import { Menu } from '@headlessui/react'

import { pxToRem } from '../../../../utils/utils'
import { SearchBar } from '../SearchBar'
import { Magnifier } from '../../../common/icons/Magnifier'

export const DesktopMockSearch: typeof Menu.Button = styled(Menu.Button)(
  ({ theme }) => css`
    background-color: transparent;
    box-shadow: none;
    position: relative;
    z-index: 1;
    color: ${theme.color.grey['500']};
    font-size: ${pxToRem(16)};
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    border-radius: ${pxToRem(20)};
    border: ${pxToRem(1)} solid ${theme.color.gray};
    width: 100%;
    max-width: ${pxToRem(368)};
    height: ${pxToRem(48)};

    &.enter {
      transition:
        150ms top,
        150ms opacity;
    }

    &.enter-from {
      opacity: 0;
    }

    &.enter-to {
      opacity: 1;
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      display: none;
    }
  `,
) as any

export const SMagnifier = styled(Magnifier)(
  ({ theme }) => css`
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
    margin-right: ${pxToRem(12)};
    margin-left: ${pxToRem(24)};
    flex: none;
    stroke: ${theme.color.medium};
  `,
)

export const MenuItems = styled(Menu.Items)(
  ({ theme }) => css`
    position: fixed;
    left: 0;
    width: 100%;
    top: ${pxToRem(88)};
    border-top: ${pxToRem(1)} solid ${theme.color.gray};
    background-color: ${theme.color.white};
    box-shadow: ${theme.boxShadow.default};

    &.enter {
      transition: 150ms top;
    }

    &.enter-from {
      top: 0;
    }

    &.enter-to {
      top: ${pxToRem(88)};
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      display: none;
    }
  `,
)

export const SSearchBar = styled(SearchBar)`
  margin: 0 auto;
  box-shadow: none;
`
