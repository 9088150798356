import { useCallback, useContext, useEffect } from 'react'

import { AuthContext } from '../AuthContext'
import { selectMode, selectUser } from '../selectors'
import { getUpdateModeAction } from '../actions'
import { AppMode } from '../types'

export const useAppMode = () => {
  const { state, dispatch } = useContext(AuthContext)
  const mode = selectMode(state)
  const user = selectUser(state)

  useEffect(() => {
    if (user && !user?.isStylist) {
      setMode('customer')
    }
  }, [user])

  const setMode = useCallback(
    (newMode: AppMode) => {
      dispatch(getUpdateModeAction(newMode))
    },
    [dispatch, getUpdateModeAction],
  )

  return {
    mode,
    setMode,
  }
}
