import styled, { css } from 'styled-components'
import { Menu } from '@headlessui/react'

import { pxToRem } from '../lib/utils'

export const Wrap = styled.div`
  position: relative;
  z-index: 1;
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};
`

export const MenuItems = styled(Menu.Items)(
  ({ theme }) => css`
    position: absolute;
    top: ${pxToRem(60)};
    right: 0;
    width: 17.25rem;
    overflow: hidden;
    max-height: 100vh;
    background-color: ${theme.color.white};
    padding: 0.6875rem 0;
    box-shadow: ${theme.boxShadow.default};
    border-radius: 0 0 ${theme.radius.small} ${theme.radius.small};

    &.enter {
      transition:
        50ms opacity linear,
        200ms max-height ease-out;
    }
    &.enterFrom {
      opacity: 0;
      max-height: 0vh;
    }
    &.enterTo {
      opacity: 1;
      max-height: 100vh;
    }
    &.leave {
      transition:
        200ms opacity linear,
        150ms max-height ease-out;
    }
    &.leaveFrom {
      opacity: 1;
      max-height: 100vh;
    }
    &.leaveTo {
      opacity: 0;
      max-height: 0vh;
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      right: 0;
      top: ${pxToRem(75)};
      left: 0;
      width: 100%;
      position: fixed;
      height: max-content;
      border-radius: 0;
    }
  `,
) as any
