import React from 'react'

import { DefaultProps } from '../../types'
import { Menu } from '../Menu'
import { MenuItem } from '../MenuItem'
import {
  CUSTOMER_ROUTES,
  EXTERNAL_ROUTES,
  getChatRoute,
  getLogoutRoute,
  getSearchRoute,
  ROUTES,
  STYLIST_ROUTES,
} from '../../lib/utils'

type Props = DefaultProps & {
  initials: string
  avatar?: string
  messagesAmount?: number
  isStylist?: boolean
  isStylistApplicant?: boolean
}

export const CustomerMenu: React.FC<Props> = ({
  className,
  initials,
  avatar,
  messagesAmount,
  isStylist,
  isStylistApplicant,
}) => {
  return (
    <Menu
      amount={messagesAmount}
      className={className}
      initials={initials}
      avatar={avatar}
    >
      <MenuItem
        mobile
        href={getSearchRoute()}
        data-testid="customer-menu-sessions"
      >
        Sessions
      </MenuItem>
      <MenuItem mobile href={EXTERNAL_ROUTES.business}>
        Workforce Styling
      </MenuItem>
      <MenuItem mobile href={EXTERNAL_ROUTES.veteran}>
        Veteran Styling
      </MenuItem>
      <MenuItem mobile href={ROUTES.blog} data-testid="customer-menu-blog">
        Blog
      </MenuItem>
      <MenuItem
        href={CUSTOMER_ROUTES.account}
        data-testid="customer-menu-account"
      >
        My Account
      </MenuItem>
      <MenuItem
        href={CUSTOMER_ROUTES.profile}
        data-testid="customer-menu-profile"
      >
        My Profile
      </MenuItem>
      <MenuItem
        href={CUSTOMER_ROUTES.bookings}
        data-testid="customer-menu-bookings"
      >
        My Sessions
      </MenuItem>
      <MenuItem
        amount={messagesAmount}
        href={getChatRoute('')}
        data-testid="customer-menu-chat"
      >
        Messages
      </MenuItem>
      {!isStylist && !isStylistApplicant && (
        <MenuItem
          href={ROUTES.becomeStylist}
          data-testid="customer-menu-join-us"
        >
          Become a Stylist
        </MenuItem>
      )}
      {!isStylist && isStylistApplicant && (
        <MenuItem
          href={STYLIST_ROUTES.joinUsStatus}
          data-testid="customer-menu-join-us-status"
        >
          Application status
        </MenuItem>
      )}
      {isStylist && (
        <MenuItem
          href={STYLIST_ROUTES.init}
          data-testid="customer-menu-to-stylist"
        >
          Switch to "Stylist"
        </MenuItem>
      )}

      <MenuItem href={getLogoutRoute()} data-testid="customer-menu-logout">
        Log out
      </MenuItem>
    </Menu>
  )
}
CustomerMenu.displayName = 'CustomerMenu'
