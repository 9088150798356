import styled, { css } from 'styled-components'

import { Arrow } from '../icons/Arrow'
import { S3NextImage } from '../S3NextImage/S3NextImage'
import { pxToRem } from '../lib/utils'

export const Wrap = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    width: ${pxToRem(360)};
    height: ${pxToRem(74)};
    box-shadow: ${pxToRem(0, 0, 30)} rgba(0, 0, 0, 0.15);
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      width: max-content;
      height: ${pxToRem(48)};
    }
  `,
)

export const InnerWrap = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    padding: ${pxToRem(16)};
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      padding: ${pxToRem(12)};
    }
  `,
)

export const MessageWrap = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: ${pxToRem(208)};
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      display: none;
    }
  `,
)

export const IconWrap = styled.div(
  ({ theme }) => css`
    position: relative;
    width: ${pxToRem(40)};
    height: ${pxToRem(40)};
    overflow: hidden;
    flex: none;
    margin: ${pxToRem(2, 12, 0, 0)};
    border-radius: 50%;
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      width: ${pxToRem(24)};
      height: ${pxToRem(24)};
      margin: ${pxToRem(0, 8, 0, 0)};
    }
  `,
)

export const SS3NextImage = styled(S3NextImage)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

export const UserName = styled.span(
  ({ theme }) => css`
    font-weight: ${theme.font.weight.medium};
    font-size: ${pxToRem(14)};
    line-height: 1.43;
    color: ${theme.color.grey['700']};
  `,
)

export const Text = styled.span(
  ({ theme }) => css`
    display: block;
    width: 100%;
    font-weight: ${theme.font.weight.regular};
    font-size: ${pxToRem(14)};
    line-height: 1.43;
    color: ${theme.color.grey['500']};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `,
)

export const Notification = styled.span(
  ({ theme }) => css`
    display: none;
    width: ${pxToRem(89)};
    font-weight: ${theme.font.weight.medium};
    font-size: ${pxToRem(14)};
    line-height: 1.43;
    white-space: nowrap;
    color: ${theme.color.grey['700']};
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      display: block;
      width: max-content;
      min-width: ${pxToRem(89)};
    }
  `,
)

export const ReplyAnchor = styled.a(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    width: ${pxToRem(67)};
    height: 100%;
    border-left: ${pxToRem(1)} solid ${theme.color.grey['200']};
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      width: ${pxToRem(41)};
    }
  `,
)

export const ReplyText = styled.span(
  ({ theme }) => css`
    font-weight: ${theme.font.weight.medium};
    font-size: ${pxToRem(14)};
    line-height: 1.43;
    color: ${theme.color.primary['600']};
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      display: none;
    }
  `,
)

export const ReplyIcon = styled(Arrow)(
  ({ theme }) => css`
    display: none;
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      display: block;
    }
  `,
)
