import React from 'react'
import { ToastContainer } from 'react-toastify'
import styled, { css } from 'styled-components'

import { pxToRem } from '../lib/utils'
import { MESSAGE_TOAST_CONTAINER_ID } from './constants'

const Wrap = styled.div(
  ({ theme }) => css`
    position: absolute;
    .Toastify__toast-container {
      padding: 0;
      width: auto;
      bottom: ${pxToRem(24)};
      right: ${pxToRem(80)};
      left: auto;

      .Toastify__toast {
        overflow: visible;
        box-shadow: none;
        margin: 0;
        padding: 0;
        min-height: unset;

        .Toastify__toast-body {
          display: block;
          margin: 0;
          padding: 0;
        }
      }
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      .Toastify__toast-container {
        bottom: ${pxToRem(16)};
        right: ${pxToRem(16)};
      }
    }
  `,
)

export const MessageToastContainer: React.FC = () => {
  return (
    <Wrap>
      <ToastContainer
        containerId={MESSAGE_TOAST_CONTAINER_ID}
        position="bottom-right"
        hideProgressBar
        autoClose={8000}
        limit={1}
        closeButton={false}
      />
    </Wrap>
  )
}
MessageToastContainer.displayName = 'MesssageToastContainer'
