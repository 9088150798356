import React from 'react'

import { DefaultProps } from '../../types'
import { Menu } from '../Menu'
import { MenuItem } from '../MenuItem'
import {
  CUSTOMER_ROUTES,
  EXTERNAL_ROUTES,
  getChatRoute,
  getLogoutRoute,
  getSearchRoute,
  ROUTES,
  // ROUTES,
  STYLIST_ROUTES,
} from '../../lib/utils'

type Props = DefaultProps & {
  initials: string
  messagesAmount?: number
  avatar?: string
}

export const StylistMenu: React.FC<Props> = ({
  className,
  initials,
  messagesAmount,
  avatar,
}) => {
  return (
    <Menu
      amount={messagesAmount}
      className={className}
      initials={initials}
      avatar={avatar}
    >
      <MenuItem
        mobile
        href={getSearchRoute()}
        data-testid="customer-menu-sessions"
      >
        Sessions
      </MenuItem>
      <MenuItem mobile href={EXTERNAL_ROUTES.business}>
        Workforce Styling
      </MenuItem>
      <MenuItem mobile href={EXTERNAL_ROUTES.veteran}>
        Veteran Styling
      </MenuItem>
      <MenuItem mobile href={ROUTES.blog} data-testid="customer-menu-blog">
        Blog
      </MenuItem>
      <MenuItem
        href={STYLIST_ROUTES.account}
        data-testid="stylist-menu-account"
      >
        My Account
      </MenuItem>
      <MenuItem
        href={STYLIST_ROUTES.profile}
        data-testid="stylist-menu-profile"
      >
        My Profile
      </MenuItem>
      <MenuItem
        href={STYLIST_ROUTES.bookings}
        data-testid="stylist-menu-bookings"
      >
        My Sessions
      </MenuItem>
      <MenuItem
        href={STYLIST_ROUTES.sessions}
        data-testid="stylist-menu-sessions"
      >
        Manage Sessions
      </MenuItem>
      <MenuItem
        amount={messagesAmount}
        href={getChatRoute('')}
        data-testid="stylist-menu-chat"
      >
        Messages
      </MenuItem>
      <MenuItem
        href={CUSTOMER_ROUTES.init}
        data-testid="stylist-menu-to-customer"
      >
        Switch to "Customer"
      </MenuItem>
      <MenuItem href={getLogoutRoute()} data-testid="stylist-menu-logout">
        Log out
      </MenuItem>
    </Menu>
  )
}
StylistMenu.displayName = 'StylistMenu'
