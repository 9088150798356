import React, { useContext, Fragment } from 'react'
import { Menu as HeadlessMenu, Transition } from '@headlessui/react'
import { ThemeContext } from 'styled-components'

import { MenuButton } from './MenuButton'
import { HamburgerButton } from './MenuButton/HamburgerButton'
import { DefaultProps } from '../types'
import { MenuItems, Wrap } from './Menu.style'
import { DefaultTheme } from '../themes'

type Props = DefaultProps & {
  initials: string
  amount?: number
  avatar?: string
  hamburgerButton?: boolean
}

export const Menu: React.FC<Props> = ({
  initials,
  avatar,
  hamburgerButton = false,
  amount,
  className,
  children,
}) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  return (
    <Wrap data-testid="headless-menu" className={className}>
      <HeadlessMenu>
        {({ open }) => (
          <>
            {hamburgerButton ? (
              <HamburgerButton isOpen={open} />
            ) : (
              <MenuButton amount={amount} avatar={avatar} isOpen={open}>
                {initials}
              </MenuButton>
            )}
            <Transition
              show={open}
              as={Fragment}
              enter="enter"
              enterFrom="enterFrom"
              enterTo="enterTo"
              leave="leave"
              leaveFrom="leaveFrom"
              leaveTo="leaveTo"
            >
              <MenuItems static theme={theme}>
                {children}
              </MenuItems>
            </Transition>
          </>
        )}
      </HeadlessMenu>
    </Wrap>
  )
}
Menu.displayName = 'Menu'
