import React, { useContext } from 'react'
import styled, { css, ThemeContext } from 'styled-components'

import { DefaultTheme } from '../themes'

const Svg = styled.svg(
  ({ theme }) => css`
    transition: 150ms transfrom;
    fill: ${theme.color.grey['500']};
    &.open {
      transform: rotate(180deg);
    }
  `,
)

type Props = {
  'data-testid'?: string
  open?: boolean
  className?: string
}

export const Chevron: React.FC<Props> = ({
  className = '',
  open,
  'data-testid': datTestid,
}) => {
  const openClass = open ? 'open' : ''
  const theme = useContext(ThemeContext) || DefaultTheme
  return (
    <Svg
      data-testid={datTestid}
      theme={theme}
      className={`${className} ${openClass}`}
      width="16"
      height="16"
      viewBox="0 0 24 24"
      stroke-width="8"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="6 9 12 15 18 9" />
    </Svg>
  )
}
Chevron.displayName = 'Chevron'
